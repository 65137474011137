.section-about {
	min-height: 80vh;

	.about-content p,
	.about-content li {
		color: #000;
	}

	.about-content h2 {
		span {
			display: inline-block;
		}
	}

	.about-content h3 {
		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
	}
	.information {
		margin-top: 20px;

		@media (min-width: 576px) {
			margin-top: 60px;
		}

		h3 {
			text-transform: uppercase;
		}
	}
}
