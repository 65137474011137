.sections-contact {
	min-height: 80vh;
	text-align: center;

	.contact-content p {
		font-size: 22px;
		font-weight: 300;

		padding: 40px 0;
		color: #000;
	}
	.icon {
		margin-right: 10px;
	}
}

.btn.linkedin {
	padding-right: 0px;
}
.btn.github {
	padding-right: 13px;
}
