.section-home {
	min-height: 100vh;
	display: flex;
	align-items: center;

	&__about {
		.salutation,
		.position-company {
			font-size: 20px;
			font-weight: 300;
			letter-spacing: -1px;
		}
		.salutation {
			margin: 0;

			span {
				display: inline-block;
				animation-name: salutation;
				animation-duration: 2s;
				animation-iteration-count: infinite;
			}

			@keyframes salutation {
				0%,
				16%,
				32% {
					transform: rotate(0deg);
				}
				8%,
				24% {
					transform: rotate(10deg);
				}
			}
		}

		h1 {
			letter-spacing: -1px;
			font-size: 30px;
			font-weight: bold;
		}
	}

	&__menu {
		a {
			display: block;
		}
	}
}

@media (min-width: 576px) {
	.section-home {
		&__about {
			.salutation,
			.position-company {
				font-size: 30px;
			}
			h1 {
				font-size: 50px;
			}
		}
		&__menu {
			li {
				display: inline-block;
			}
			a {
				margin-right: 20px;
			}
		}
	}
}
