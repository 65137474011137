.desktop-header {
	margin-top: 20px;	
	text-align: right;
	margin-right: 60px;
	
	@media (min-width: 768px) {
		margin-right: 20px;
	}

	@media (min-width: 992px) {
		margin-right: 40px;
	}

}
.menu-desktop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: linear-gradient(0deg, rgba(232, 220, 242, 0) 0%, rgba(244, 206, 248, 1) 70%);
	z-index: 7;
	transition: transform 0.15s linear, opacity 0.15s linear;
	display: none;
	@media (min-width: 992px) {
		display: block;
	}

	&.hidden {
		transform: translateY(-60px);
		opacity: 0;
	}

	a {
		text-decoration: none;
	}

	.container {
		padding-left: 30px;
	}

	.menu-desktop-inner {
		display: flex;
		padding: 20px;
		padding-bottom: 60px;
	}

	h1 {
		font-size: 30px;
		font-weight: bold;
		border-right: 1px solid #7a0088;
		padding-right: 20px;
	}

	.menu-links {
		margin-top: 5px;
		margin-left: 20px;
		flex-grow: 1;

		li {
			display: inline-block;
		}

		a {
			margin-right: 15px;
			font-size: 18px;
			color: #3c133f;
		}
	}

	.social-media {
		display: flex;
		.icon {
			font-size: 30px;
			margin-right: 20px;
		}
	}
}

.mobile-menu {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	background: rgba(0, 0, 0, 0.9);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	color: #fff;
	padding: 20px;
	padding-top: 90px;

	ul {
		li {
			border-top: 1px solid #666;

			&:last-child {
				border-bottom: 1px solid #666;
			}

			a {
				display: block;
				font-size: 20px;
				padding: 10px 0;

				&,
				&:hover {
					color: #fff;
					text-decoration: none;
				}
			}
		}
	}

	.social-media {
		text-align: center;
		.icon {
			font-size: 40px;
			margin: 20px;
			color: #fff;
		}
	}
}

.mobile-menu-header {
	&.hidden {
		transform: translateY(-60px);
		//opacity: 0;
	}
}

.mobile-menu-header__background {
	background: linear-gradient(0deg, rgba(232, 220, 242, 0) 0%, rgba(244, 206, 248, 1) 70%);
	transition: transform 0.15s linear, opacity 0.15s linear;
	height: 90px;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
}

.mobile-menu-icon {
	display: block;
	position: fixed;
	top: 30px;
	right: 15px;
	z-index: 6;

	div {
		width: 40px;
		height: 24px;
		position: relative;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.5s ease-in-out;
		-moz-transition: 0.5s ease-in-out;
		-o-transition: 0.5s ease-in-out;
		transition: 0.5s ease-in-out;
		cursor: pointer;
	}

	span {
		display: block;
		position: absolute;
		height: 6px;
		width: 100%;
		background: #7a0088;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;

		&:nth-child(1) {
			top: 0px;
			-webkit-transform-origin: left center;
			-moz-transform-origin: left center;
			-o-transform-origin: left center;
			transform-origin: left center;
		}
		&:nth-child(2) {
			top: 9px;
			-webkit-transform-origin: left center;
			-moz-transform-origin: left center;
			-o-transform-origin: left center;
			transform-origin: left center;
		}
		&:nth-child(3) {
			top: 18px;
			-webkit-transform-origin: left center;
			-moz-transform-origin: left center;
			-o-transform-origin: left center;
			transform-origin: left center;
		}
	}

	&.open {
		span {
			background-color: rgba(244, 206, 248, 1);
			&:nth-child(1) {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
				top: -3px;
				left: 8px;
			}
			&:nth-child(2) {
				width: 0%;
				opacity: 0;
			}
			&:nth-child(3) {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
				top: 26px;
				left: 8px;
			}
		}
	}
}

@media (min-width: 992px) {
	.mobile-menu-header {
		display: none;
	}
}
